@import '../../assets/sass/_variables.sass'; 

.faq-see-more {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    & span {
        color: $primary;
        font-weight: 700;
        text-decoration: underline;
        line-height: 1.5rem;
        cursor: pointer;
        &:hover {
            opacity: .6;
        }
    }
}