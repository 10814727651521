@import '../../assets/sass/variables';

.list-acess-rules {
    padding: 0 1.5rem;

    & li {
        list-style: inside !important;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.privacy-check {
    text-decoration: underline;
    opacity: .8;

    &:hover {
        opacity: 1;
    }
}