 

.bg-compliance {
    
    & .slick-next:before, .slick-prev:before {
        color: #FFF !important;
        background-color: #ff660050;
        width: 2rem;
    }
}

@media (max-width: 480px) {
    .bg-compliance {

        & img {
            height: 400px;
        }
    }
}