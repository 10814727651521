@import '../../assets/sass/variables';

.bg-scroll {
    position: sticky;
    top: calc(1.5rem + 104px);
}

.card-side {   
    background-color: #FFF;
    border-radius: 5px;
    & small {
        font-size: .9rem;
    }
    & .btn-card-side {
        background-color: $primary;
        color: #FFF;
        width: 100%;
        border-radius: 0px;
        transition: all .3s;
    }
    & .display-3 {
        font-size: 2.8rem;
        font-weight: 700;
        color: #495057;
    }
    & p > small {
        font-size: 1rem;
    }
}
