 @import '../../assets/sass/_variables.sass';
 
.body-not-found {
    background-color: $primary;
}

.not-found {
    height: 100vh;
    color: #FFF;
    font-size: 1.25rem;
}

.four-zero-four {
    font-size: 2rem;
}

.link-home {
    color: #FFF;
    &:hover {
        opacity: .8;
        color: #FFF;
    }
}