@import '../../assets/sass/variables';

.profile{
    padding: 3rem 0;
}

.profile *{
    font-family: $font-family;
}

.logo-info-company {
    border: 2px solid #707070;
    border-radius: 50%;
    width: 120px;
    height: 120px;
}

input[type="file"] {
    display: none;
}

.profile-container {
    max-width: 900px;
    padding: 40px;
    background-color: #fff;
    margin: 50px auto;
    border-radius: 2.5px;

    h1 {
        color: #666666 !important;
        font-size: 2rem;
        font-weight: 600;
    }
  }

.profile h1{
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
    padding-bottom: 1.5rem;
}

.profile .profile-box{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    max-width: 327px;
    width: 100%;
    border-right: 1px solid #ccc;
}

.profile .profile-box:last-child{
    border-right: none;
}

.profile .profile-box *{
    text-align: left;
}

.profile .profile-pic{
    max-width: 194px;
    justify-content: center;
    align-items: center;
}

.profile .profile-pic img{
    width: 150px;
    height: 150px;
    border-radius: 50%;
}

.profile .form-group{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.profile .form-group p{
    margin: 0;
    padding-right: 15px;
}

.profile .form-group input{
    border: none;
}

.profile .save-data{
    display: flex;
    justify-content: flex-end;
}

.profile .save-data button{
    color: #fff;
    background-color: #fd7e14;
    border-color: #fd7e14;
    padding: .375rem .75rem;
    font-size: 1rem;
    margin: 10px;
    border-style: unset;
}

.profile .save-data button:hover{
    color: #fff;
    background-color: #ffC60A;
    border-color: #ffC60A;
}

.profile .email{
    color: #FE340C;
}

.profile .btn-container{
    width: 84px;
}

.profile .btn:focus{
    box-shadow: unset;
}